<template>
  <article :class="{active: this.isActive}">
    <button
      @click="toggle"
      type="button"
      class="item-header"
    >
      <h4>{{title}}</h4>
      <span class="control"></span>
    </button>
    <div class="content">
      <v-img
        ref="image"
        maxWidth="100%"
        maxHeight="300px"
        :lazy-src="getLazySrc(content.image)"
        :src="optimizedImageURL(content.image, { width: 600, quality: 95 })"
        :aspect-ratio="1"
        contain
        transition="fade-transition"
      />
      <v-fade-transition>
        <div
          v-show="this.isActive"
          class="text-container -full -above"
        >
          <MarkdownBlock
            ref="text"
            class="text"
            linkify
            tag="div"
            :inline="false"
            :text="content.description"
          />
        </div>
      </v-fade-transition>
    </div>
  </article>
</template>

<script>
// import ScrollParallax from '@/animations/gsap/scroll-parallax'
import { getLazySrc, optimizedImageURL } from '@/services/OptimizedImage'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ActiveStateMixin from '@/mixins/ActiveStateMixin'
const stateMixin = ActiveStateMixin()
export default {
  name: 'DeepeningItem',
  mixins: [stateMixin],
  props: {
    title: {
      type: String
    },
    content: {
      type: Object
    }
  },
  components: { MarkdownBlock },
  methods: {
    getLazySrc,
    optimizedImageURL
  },
  watch: {
    isActive (value) {
      if (value) {
        this.$emit('state-change')
      } else {
        this.$refs.text.$el.scrollTop = 0
      }
    }
  },
  computed: {
    metadata () {
      return JSON.parse(this.content.image.metaData)
    }
  }
}
</script>
<style lang="scss" scoped>
.item-header {
  width: 100%;
  outline: none;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: rem(90px);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background: $color-detail;
    width: 100%;
  }
}

h4 {
  max-width: rem(180px);
}

.control {
  position: relative;
  width: rem(50px);
  height: rem(50px);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: rem(10px);
    height: rem(1px);
    left: 50%;
    z-index: 2;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: #000;
    transition: transform 0.3s;
  }

  &::after {
    transform: translate3d(-50%, -50%, 0) rotate(90deg);
  }

  article.active & {
    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    &::before {
      transform: translate3d(-50%, -50%, 0) rotate(-180deg);
    }
  }
}

.content {
  position: relative;
  padding: rem(24px 0);
}

.text-container {
  z-index: 1;

  background: $color-bg;
  padding-top: rem(12px);
  padding-bottom: rem(12px);

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 22px;
    left: 0;
    z-index: 2;
  }

  &::before {
    height: rem(22px);
    background: linear-gradient(180deg, transparentize($color-bg, 0) 0%, transparentize($color-bg, 1) 100%);
    top: rem(12px);
  }

  &::after {
    height: rem(34px);
    background: linear-gradient(0deg, transparentize($color-bg, 0) 0%, transparentize($color-bg, 1) 100%);
    bottom: rem(12px);
  }
}

.text {
  @include scrollbars(rem(1px), $color-detail, $color-bg );
  max-height: 100%;
  overflow: auto;
  padding-top: rem(24px);
  padding-bottom: rem(36px);
  padding-right: rem(24px);
  -webkit-overflow-scrolling: touch;
}

</style>
