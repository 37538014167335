<template>
  <section class="deepeningSection">
    <div class="bg-t-1 -below"></div>
    <div class="bg-t-2 -below"></div>
    <div class="bg-t-3 -below"></div>
    <!-- <div class="bg-b-1 -below"></div>
    <div class="bg-b-2 -below"></div> -->
    <v-container class="-above">
      <v-row class="mb-15">
        <v-col
          cols="12"
          sm="6"
          md="4"
          offset-md="1"
          offset-lg="2"
        >
          <div ref="title">
            <h2 :class="gradientClass">{{title}}</h2>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="5"
          offset-md="1"
          lg="4"
          offset-lg="1"

        >
          <MarkdownBlock
            ref="text"
            linkify
            class="pt-md-2"
            tag="div"
            :inline="false"
            :text="description"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          lg="9"
          offset-lg="2"
          class="deepening-container"
        >
          <p class="mb-2 mb-md-4 mt-4">{{focusIntro}}</p>
          <v-row>
            <v-col
              v-for="(item, i) in focus"
              :key="i"
              cols="12"
              :md="Math.max(3, ~~(12/focus.length))"
              class="deepening-item"
              :class="{last: i === focus.length - 1}"
            >
              <DeepeningItem
                ref="items"
                v-bind="item"
                @state-change="onStateItemChange(i)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// import ScrollParallax from '@/animations/gsap/scroll-parallax'
import { MarkdownBlock } from '@monogrid/vue-lib'

import GradientClass from '@/mixins/GradientClass'
import DeepeningItem from '@/atoms/DeepeningItem'

export default {
  name: 'deepeningSection',
  mixins: [GradientClass],
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    focusIntro: {
      type: String
    },
    focus: {
      type: Array
    }
  },
  components: {
    MarkdownBlock,
    DeepeningItem
  },
  methods: {
    onStateItemChange (current) {
      this.$refs.items.forEach((item, i) => {
        if (i !== current) {
          item.isActive = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.deepeningSection {
  position: relative;
  padding: rem(76px 0 20px);
  @include breakpoint('md-and-up') {
    // padding: rem(224px 0 360px);
    padding: rem(224px 0 20px);
  }
}

.bg-t-1,
.bg-t-2,
.bg-t-3,
.bg-b-1,
.bg-b-2 {
  position: absolute;
  pointer-events: none;
}

.bg-t-1,
.bg-t-2,
.bg-t-3 {
  width: rem(500px);
  height: rem(500px);
  @include breakpoint('md-and-up') {
    width: rem(900px);
    height: rem(900px);
  }
}

.bg-b-1,
.bg-b-2 {
  display: none;
  @include breakpoint('md-and-up') {
    display: block;
    width: rem(600px);
    height: rem(600px);
  }
}

.bg-t-1 {
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  background: $bg-emo-gradient-2;
}

.bg-t-2 {
  right: 20%;
  top: 0;
  transform: translate(50%, -50%);
  background: $bg-emo-gradient-1;
}

.bg-t-3 {
  right: 40%;
  top: 0;
  transform: translate(50%, -50%);
  background: $bg-emo-gradient-3;
}

.bg-b-1 {
  left: 10%;
  bottom: 0;
  transform: translate(-50%, 40%);
  background: $bg-emo-gradient-3;
}

.bg-b-2 {
  left: 30%;
  bottom: 0;
  transform: translate(-50%, 50%);
  background: $bg-emo-gradient-1;
}

.deepening-container {
  // background-color: $color-bg;
  border-top: 1px solid $color-detail;
}

.deepening-item {
  padding-right: rem(12px);

  &:not(:first-child) {
    padding-left: rem(12px);
  }

  &:not(:last-child) {
    @include breakpoint('md-and-up') {
      border-right: 1px solid $color-detail;
    }
  }
}
</style>
