import { render, staticRenderFns } from "./DeepeningItem.vue?vue&type=template&id=98e55eb6&scoped=true&"
import script from "./DeepeningItem.vue?vue&type=script&lang=js&"
export * from "./DeepeningItem.vue?vue&type=script&lang=js&"
import style0 from "./DeepeningItem.vue?vue&type=style&index=0&id=98e55eb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e55eb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VFadeTransition,VImg})
