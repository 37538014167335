<template>
  <BasePage :isLoaded="isLoaded">
    <template v-slot:content>
      <ButtonScrollTo
        label="Scroll"
        :smallOnMobile="false"
        target="target"
        class="invite-to-scroll"
      />
      <template v-for="(section, i) in modularPage.content">
        <component
          v-if="sections[section._repeaterType]"
          :key="i"
          :is="sections[section._repeaterType]"
          :id="i === 1 ? 'target' : ''"
          v-bind="section"
        />
      </template>
    </template>
  </BasePage>
</template>

<script>
import GetModularPage from '@/graphql/GetModularPage.gql'
import BasePage from '@/organisms/BasePage'
import titleAndText from '@/molecules/TitleAndText'
import text from '@/molecules/Text'
import imageSection from '@/molecules/Image'
import quote from '@/molecules/Quote'
import deepening from '@/molecules/Deepening'
import gallery from '@/molecules/Gallery'
import sliderStep from '@/molecules/SliderStep'
import ButtonScrollTo from '@/atoms/ButtonScrollTo.vue'

export default {
  name: 'ModularPage',
  components: {
    BasePage,
    ButtonScrollTo
  },
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      sections: {
        titleAndText,
        image: imageSection,
        quote,
        text,
        deepening,
        gallery,
        sliderStep
      }
    }
  },
  apollo: {
    modularPage: {
      query: GetModularPage,
      variables () {
        return {
          slug: this.slug
        }
      },
      async result (res) {
        if (!res.data.modularPage) {
          this.$router.push({ name: 'Home' })
        }
      }
    }
  },
  computed: {
    isLoaded () {
      return !!(this.modularPage && this.modularPage._slug === this.slug)
    }
  },
  metaInfo: function () {
    if (this.isLoaded) {
      return {
        title: this.modularPage.title,
        meta: [
          { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.modularPage.title },
          { vmid: 'description', name: 'description', content: this.modularPage.metaDescription },
          { vmid: 'ogdescription', property: 'og:description', content: this.modularPage.metaDescription },
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/media/' + this.modularPage.metaImage.id }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .page-content {
  padding: rem(150px 0);
}

.invite-to-scroll {
  position: absolute;
  top: 80vh;
  left: 15vw;
  z-index: 10;

  @include breakpoint ('lg-and-down') {
    left: 8vw;
  }

  @include breakpoint ('md-and-down') {
    display: none !important;
  }
}
</style>
