<template>
  <section>
    <div class="bg-1 -below"></div>
    <div class="bg-2 -below"></div>
    <v-container>
      <v-row class="-above">
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          lg="8"
          offset-lg="2"
        >
          <MarkdownBlock
            ref="quote"
            linkify
            tag="blockquote"
            :inline="true"
            :text="text"
            :class="{'h1-big': big === true, 'h2': big !== true, [gradientClass]: true}"
          />
        </v-col>
      </v-row>
      <v-row v-if="gallery && gallery.length > 0" class="mt-n10 -below">
        <v-col
          v-for="(image, i) in gallery"
          :key="i"
          cols="12"
          :sm="gallery.length === 1 ? 6 : 5"
          :offset-sm="gallery.length === 1 ? 6 : 1"
          lg="3"
          :offset-lg="i === 0 ? 5 : 1"
          ref="image"
        >
          <v-img
            width="100%"
            height="100%"
            maxWidth="100%"
            max-height="425px"
            :lazy-src="getLazySrc(image)"
            :src="optimizedImageURL(image, { width: 600, quality: 95 })"
            :aspect-ratio="getAspectRatio(image)"
            contain
            transition="fade-transition"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { getLazySrc, optimizedImageURL, getAspectRatio } from '@/services/OptimizedImage'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ScrollParallax from '@/animations/gsap/scroll-parallax'
import ScrollAppear from '@/animations/gsap/scroll-appear'

import GradientClass from '@/mixins/GradientClass'

export default {
  name: 'quote',
  mixins: [GradientClass],
  props: {
    big: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  components: {
    MarkdownBlock
  },
  data () {
    const gallery = (this.images || []).map(item => {
      const image = { ...item }
      image.meta = JSON.parse(image.metaData)
      image.meta.ratio = image.meta['image-width'] / image.meta['image-height']
      return image
    })
    return {
      gallery: Object.freeze(gallery)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tls = []
      if (this.$refs.image) {
        this.$refs.image.forEach((image, i) => {
          this.tls.push(ScrollParallax(image, i !== 0 && this.$vuetify.breakpoint.mdAndUp ? -50 : 50))
        })
      }
      this.tls.push(ScrollAppear(this.$refs.quote.$el))
    })
  },
  beforeDestroy () {
    this.tls.forEach(tl => tl.kill())
  },
  methods: {
    getLazySrc,
    optimizedImageURL,
    getAspectRatio
  }
}
</script>

<style lang="scss" scoped>
.bg-1,
.bg-2 {
  position: absolute;
  left: 0%;
  top: 50%;
  width: rem(500px);
  height: rem(500px);
  background: $bg-emo-gradient-3;

  transform: translate(0%, -50%);
  pointer-events: none;
}

.bg-1 {
  background: $bg-emo-gradient-1;

  transform: translate(50%, -20%);
}

.h2 {
  @include breakpoint('md-and-up') {
    font-size: rem(64px);
    line-height: rem(62px);
  }
}
</style>
