import { gsap } from 'gsap'

export default function scrollParallax (section, deltaY = 50) {
  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 0.5
    }
  })
  timeline.fromTo(
    section,
    {
      y: deltaY
    },
    {
      duration: 1,
      ease: 'none',
      y: -1 * deltaY
    },
    0
  )
  timeline.fromTo(
    section,
    {
      opacity: 0
    },
    {
      duration: 0.3,
      ease: 'none',
      opacity: 1
    },
    0
  )

  return timeline
}
