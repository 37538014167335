<template>
  <section>
    <div class="bg-1 -below"></div>
    <div class="bg-2 -below"></div>
    <v-container class="-above">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          :offset-sm="alignRight ? 6 : 0"
          md="5"
          :offset-md="alignRight ? 6 : 1"
          lg="4"
          :offset-lg="alignRight ? 6 : 2"
          ref="image"
        >
          <v-img
            maxWidth="100%"
            :lazy-src="getLazySrc(image)"
            :src="optimizedImageURL(image, { width: 800, quality: 95 })"
            :aspect-ratio="getAspectRatio(image)"
            contain
            transition="fade-transition"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { getLazySrc, optimizedImageURL, getAspectRatio } from '@/services/OptimizedImage'
import ScrollParallax from '@/animations/gsap/scroll-parallax'
export default {
  name: 'imageSection',
  props: {
    image: {
      type: Object
    },
    alignRight: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tl = ScrollParallax(this.$refs.image)
    })
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    getLazySrc,
    optimizedImageURL,
    getAspectRatio
  }
}
</script>
<style lang="scss" scoped>

.bg-1,
.bg-2 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: rem(500px);
  height: rem(500px);
  background: $bg-emo-gradient-3;

  transform: translate(-50%, -50%);
  pointer-events: none;
}

.bg-1 {
  background: $bg-emo-gradient-1;

  transform: translate(-80%, -80%);
}

section {
  padding: rem(0);
}
</style>
