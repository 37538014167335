<template>
  <section>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="8"
          offset-sm="2"
          md="4"
          :offset-md="alignRight === true ? 7 : 2"
        >
          <MarkdownBlock
            ref="text"
            linkify
            tag="div"
            :inline="false"
            :text="text"
          />
          <Cta v-if="ctaAction && ctaAction !== 'none'" :action="ctaAction" :label="ctaLabel"/>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'
import ScrollAppear from '@/animations/gsap/scroll-appear'

import GradientClass from '@/mixins/GradientClass'
import Cta from '@/molecules/Cta'

export default {
  name: 'titleAndText',
  mixins: [GradientClass],
  props: {
    alignRight: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    ctaAction: {
      type: String
    },
    ctaLabel: {
      type: String
    }
  },
  components: {
    MarkdownBlock,
    Cta
  },
  mounted () {
    this.$nextTick(() => {
      this.tl = ScrollAppear(this.$refs.text.$el)
    })
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  }
}
</script>
<style lang="scss" scoped>
</style>
